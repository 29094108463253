import { AxiosRequestConfig } from 'axios'
import mime from 'mime-types'
import { FacilityEntities, FacilityEntity } from './FacilityRepository'
import {
  Issue,
  IssueApi,
  IssueCreateRequest,
  IssueUpdateRequest,
  AttachmentFile,
  VrCoordinates,
  PublicApi,
  LinkedIssueRequest,
  UnLinkIssueRequest,
  Facility,
} from '@/api/api'
import { AttachmentOperationsEntity, FileCreateRequestEntity, AttachmentFileEntity } from '@/repository/FileRepository'
import { CommentEntity } from '@/repository/CommentRepository'
import { LinkPanoramaCreateEntity, LinkPanoramaUpdateEntity, PanoramaEntity } from '@/repository/ShotPointRepository'
import { toCamelCase, toSnakeCase } from '@/utils/common'
import { FILE_STATUS } from '@/constants'
import { Coordinate } from '@/types/property'
import SimpleIssueEntity from '@/components/issue/SimpleIssueEntity.class'

export interface LinkFloorPlanUpdateRequestLinkEntity {
  floorPlanId: number
  coordinate: Coordinate
}

export interface LinkFloorPlanUpdateRequestPatchEntity {
  coordinate: Coordinate
}
export interface LinkFloorPlanCreateEntity {
  link: LinkFloorPlanUpdateRequestLinkEntity
}
export interface CreateIssueEntity {
  issueCode: string
  title: string
  description: string
  assignees: number[]
  priority: number
  notificationTargets: number[]
  dueDate: string | null
  propertyId: number
  floorId: number | null
  files?: File[]
  attachmentOperations?: AttachmentOperationsEntity
  linkedPanoramaOperation?: LinkPanoramaCreateEntity
  linkedFloorPlanOperation?: LinkFloorPlanCreateEntity
}

export interface PaginationEntity {
  page: number
  perPage: number
  totalCount: number
}

export interface LinkFloorPlanUpdateEntity {
  link?: LinkFloorPlanUpdateRequestLinkEntity
  patch?: LinkFloorPlanUpdateRequestPatchEntity
  unlink?: boolean
}

export interface UpdateIssueEntity {
  issueId?: number
  issueGroupId?: number
  title?: string
  description?: string
  assignees?: number[]
  notificationTargets?: number[]
  dueDate?: string | null
  status?: number
  priority?: number
  files?: (File | AttachmentFileEntity)[]
  propertyId?: number
  floorId?: number | null
  attachmentOperations?: AttachmentOperationsEntity
  linkedPanoramaOperation?: LinkPanoramaUpdateEntity
  linkedFloorPlanOperation?: LinkFloorPlanUpdateEntity
  links?: LinkedIssueRequest
  unlinks?: UnLinkIssueRequest
}

export interface LinkedPanoramaEntity {
  latestPanorama: PanoramaEntity
  pvrObjectId: number
  shotPointId: number
  vrCoordinates: VrCoordinates
}

export interface LinkedFloorPlanEntity {
  floorPlanId: number
  floorPlanObjectId: number
  coordinate: Coordinate
}

export interface issueUpdatedStatusEntity {
  issueId: number
  updatedAt: string
}

export interface IssueEntity extends CreateIssueEntity {
  id: number
  status: number
  issueCode: string
  isBookmarked: boolean
  createdBy: number
  dueDateAlert: number | null
  createdAt: string
  updatedAt: string
  propertyName?: string
  floorName?: string
  userPermissions: string[]
  linkedShotPoint?: {
    id: number
  }
  issueUpdatedStatus?: issueUpdatedStatusEntity
  linkedFloorPlan?: LinkedFloorPlanEntity
  linkedPanorama?: LinkedPanoramaEntity
  linkedIssuesCount?: number
  linkedFacilitiesCount?: number
  disabled?: boolean
}

export interface IssueDetailEntity extends IssueEntity {
  attachmentFiles?: AttachmentFileEntity[]
  comments?: CommentEntity[]
  linkedFloorPlan?: LinkedFloorPlanEntity
  linkedPanorama?: LinkedPanoramaEntity
  linkedIssues?: IssueEntity[]
  linkedFacilities?: FacilityEntity[]
}

export interface PublicIssueEntity {
  issueCode: string
}

export interface IssueEntities {
  issues: IssueEntity[]
  pagination: PaginationEntity
}

export interface FilterIssueEntity {
  issueId?: number
  isBookmarked?: boolean
  statuses?: number[]
  priorities?: number[]
  assignees?: number[]
  creators?: number[]
  properties?: number[]
  floor?: number[]
  dueDateMin?: string
  dueDateMax?: string
  linkType?: string
  isExcludingLinked?: number
  orderBy?: string
  sort?: string
  page?: number
  perPage?: number
  title?: string
  fromUpdatedAt?: string
  toUpdatedAt?: string
  isAssignedMe?: boolean
  fromCreatedAt?: string
  toCreatedAt?: string
  hasNoAssignee?: boolean
  hasNoDueDate?: boolean
  isDueDateExceeeded?: boolean

  // not to be send to server
  dueDateSelection?: number[]
}

export interface ReferredPropertyCreateRequest {
  propertyId: number
  floorId?: number
}

export interface IssueCreateRequestAttributes {
  referredProperty: ReferredPropertyCreateRequest
}

export interface IssueFilterItemsForAssignees {
  assignees?: number[]
}

export class IssueRepository {
  private readonly issueApi: IssueApi
  private readonly publicApi: PublicApi
  constructor(issueApi: IssueApi, publicApi: PublicApi) {
    this.issueApi = issueApi
    this.publicApi = publicApi
  }

  async searchIssues(params?: FilterIssueEntity, options?: AxiosRequestConfig): Promise<IssueEntities> {
    // 0: 担当者未設定のため、APIで送らないようにするため。
    let assignee
    if (params?.assignees && params?.assignees.includes(0) && params?.assignees.length > 1) {
      assignee = params.assignees.filter(id => id !== 0)
    } else if (params?.assignees && !params?.assignees.includes(0)) {
      assignee = params?.assignees
    }
    const { data } = await this.issueApi.searchIssues(
      params?.isBookmarked,
      params?.statuses,
      assignee,
      params?.creators,
      params?.properties,
      params?.floor,
      params?.priorities,
      params?.dueDateMin,
      params?.dueDateMax,
      params?.linkType,
      params?.isExcludingLinked,
      params?.orderBy,
      params?.sort,
      params?.page,
      params?.perPage,
      params?.title,
      params?.fromCreatedAt && SimpleIssueEntity.jstToUtcDateTime(params.fromCreatedAt),
      params?.toCreatedAt && SimpleIssueEntity.jstToUtcDateTime(params.toCreatedAt),
      params?.fromUpdatedAt && SimpleIssueEntity.jstToUtcDateTime(params.fromUpdatedAt),
      params?.toUpdatedAt && SimpleIssueEntity.jstToUtcDateTime(params.toUpdatedAt),
      params?.isAssignedMe,
      params?.hasNoAssignee,
      params?.hasNoDueDate,
      params?.isDueDateExceeeded,
      options
    )
    if (data?.data && data.ok) {
      const issuesResponse = {
        issues: data.data.map((issue: Issue): IssueEntity => {
          return {
            ...toCamelCase(issue),
            floorId: issue.attributes.referred_property?.floor_id,
            floorName: issue.attributes.referred_property?.floor_name || '',
            propertyId: issue.attributes.referred_property.property_id,
            propertyName: issue.attributes.referred_property.property_name,
          }
        }),
        pagination: { ...toCamelCase(data.pagination) },
      }
      return issuesResponse
    } else {
      throw new Error('API Error')
    }
  }

  async downloadCsvSearchIssues(params?: FilterIssueEntity, options?: AxiosRequestConfig) {
    // 0: 担当者未設定のため、APIで送らないようにするため。
    let assignee
    if (params?.assignees && params?.assignees.includes(0) && params?.assignees.length > 1) {
      assignee = params.assignees.filter(id => id !== 0)
    } else if (params?.assignees && !params?.assignees.includes(0)) {
      assignee = params?.assignees
    }
    const { data } = await this.issueApi.downloadIssues(
      params?.isBookmarked,
      params?.statuses,
      assignee,
      params?.creators,
      params?.properties,
      params?.floor,
      params?.dueDateMin,
      params?.dueDateMax,
      params?.orderBy,
      params?.sort,
      params?.page,
      params?.perPage,
      params?.title,
      params?.fromCreatedAt && SimpleIssueEntity.jstToUtcDateTime(params.fromCreatedAt),
      params?.toCreatedAt && SimpleIssueEntity.jstToUtcDateTime(params.toCreatedAt),
      params?.fromUpdatedAt && SimpleIssueEntity.jstToUtcDateTime(params.fromUpdatedAt),
      params?.toUpdatedAt && SimpleIssueEntity.jstToUtcDateTime(params.toUpdatedAt),
      params?.isAssignedMe,
      params?.hasNoAssignee,
      params?.hasNoDueDate,
      params?.isDueDateExceeeded,
      options
    )
    if (data && typeof data === 'string') {
      return data
    } else {
      throw new Error('API Error')
    }
  }

  async getIssue(id: number, options?: AxiosRequestConfig): Promise<IssueDetailEntity | null> {
    const { data } = await this.issueApi.getIssue(id, options)
    if (data?.data && data.ok) {
      const issue = data.data
      const attachmentFiles = await this.attachmentEntityCreator(issue.attachment_files)
      return {
        ...toCamelCase(issue),
        floorId: issue.attributes.referred_property?.floor_id,
        floorName: issue.attributes.referred_property?.floor_name || '',
        propertyId: issue.attributes.referred_property.property_id,
        propertyName: issue.attributes.referred_property.property_name,
        attachmentFiles,
      }
    } else {
      return null
    }
  }

  async getPublicIssue(id: number, options?: AxiosRequestConfig): Promise<PublicIssueEntity | null> {
    const { data } = await this.publicApi.getIssueById(id, options)
    if (data?.data && data.ok) {
      const issue = data.data
      return {
        ...toCamelCase(issue),
      }
    } else {
      return null
    }
  }

  async registerIssueBookmark(id: number): Promise<boolean> {
    try {
      const { data } = await this.issueApi.registerIssueBookmark(id)
      if (!data.ok) {
        throw new Error('API Error')
      }
      return data.ok
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async unregisterIssueBookmark(id: number): Promise<boolean> {
    try {
      const { data } = await this.issueApi.unregisterIssueBookmark(id)
      if (!data.ok) {
        throw new Error('API Error')
      }
      return data.ok
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async deleteIssue(id: number): Promise<boolean> {
    try {
      const { data } = await this.issueApi.deleteIssue(id)
      if (!data.ok) {
        throw new Error('API Error')
      }
      return true
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async registerIssue(issue: CreateIssueEntity): Promise<IssueDetailEntity> {
    try {
      const request: IssueCreateRequest = {
        title: issue.title,
        description: issue.description,
        assignees: issue.assignees,
        notification_targets: issue.notificationTargets,
        priority: issue.priority,
        attributes: {
          referred_property: {
            property_id: issue.propertyId,
          },
        },
      }
      if (issue.dueDate) {
        request.due_date = issue.dueDate
      }
      if (issue.floorId) {
        request.attributes.referred_property.floor_id = issue.floorId
      }
      if (
        issue.attachmentOperations &&
        issue.attachmentOperations.createFiles &&
        issue.attachmentOperations.createFiles.length !== 0
      ) {
        request.attachment_operations = {
          uuid: issue.attachmentOperations.uuid,
          create_files: issue.attachmentOperations.createFiles?.map((createFile: FileCreateRequestEntity) => {
            return {
              name: createFile.name,
              path: createFile.path,
              is_protected: createFile.isProtected,
            }
          }),
        }
      }
      if (issue.linkedPanoramaOperation?.link) {
        request.linked_panorama_operation = {
          link: {
            shot_point_id: issue.linkedPanoramaOperation.link.shotPointId,
            vr_coordinates: issue.linkedPanoramaOperation.link.vrCoordinates,
          },
        }
      }
      if (issue.linkedFloorPlanOperation?.link) {
        request.linked_floor_plan_operation = {
          link: {
            floor_plan_id: issue.linkedFloorPlanOperation.link.floorPlanId,
            coordinate: issue.linkedFloorPlanOperation.link.coordinate,
          },
        }
      }
      const { data } = await this.issueApi.createIssue(request)
      const createdIssue = data?.data
      if (!createdIssue || !data.ok) {
        throw new Error('API Error')
      }
      const attachmentFiles = await this.attachmentEntityCreator(createdIssue.attachment_files)
      return {
        ...toCamelCase(createdIssue),
        floorId: createdIssue.attributes.referred_property?.floor_id,
        floorName: createdIssue.attributes.referred_property?.floor_name || '',
        propertyId: createdIssue.attributes.referred_property.property_id,
        propertyName: createdIssue.attributes.referred_property.property_name,
        attachmentFiles,
      }
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async updateIssue(issue: UpdateIssueEntity, issueId: number): Promise<IssueDetailEntity> {
    const request: IssueUpdateRequest = {}
    if (typeof issue.title !== 'undefined') {
      request.title = issue.title
    }
    if (typeof issue.description !== 'undefined') {
      request.description = issue.description
    }
    if (typeof issue.assignees !== 'undefined') {
      request.assignees = issue.assignees
    }
    if (typeof issue.notificationTargets !== 'undefined') {
      request.notification_targets = issue.notificationTargets
    }
    if (typeof issue.dueDate !== 'undefined') {
      if (issue.dueDate === null) {
        request.due_date = ''
      } else {
        request.due_date = issue.dueDate
      }
    }
    if (issue.status) {
      request.status = issue.status
    }
    if (typeof issue.priority !== 'undefined') {
      request.priority = issue.priority
    }
    if (issue.linkedPanoramaOperation) {
      request.linked_panorama_operation = toSnakeCase(issue.linkedPanoramaOperation)
    }
    if (issue.linkedFloorPlanOperation) {
      request.linked_floor_plan_operation = toSnakeCase(issue.linkedFloorPlanOperation)
    }

    if (issue.attachmentOperations) {
      if (issue.attachmentOperations.createFiles && issue.attachmentOperations.createFiles.length !== 0) {
        request.attachment_operations = {
          uuid: issue.attachmentOperations.uuid,
          create_files: issue.attachmentOperations.createFiles?.map((createFile: FileCreateRequestEntity) => {
            return {
              name: createFile.name,
              path: createFile.path,
              is_protected: createFile.isProtected,
            }
          }),
        }
      }
      // ファイル削除
      if (issue.attachmentOperations.deleteFiles && issue.attachmentOperations.deleteFiles.length !== 0) {
        request.attachment_operations = Object.assign(request.attachment_operations ?? {}, {
          delete_files: issue.attachmentOperations.deleteFiles,
        })
      }
    }

    if (issue.links) {
      request.links = issue.links
    }

    if (issue.unlinks) {
      request.unlinks = issue.unlinks
    }

    if (issue.propertyId) {
      request.attributes = {
        referred_property: {
          property_id: issue.propertyId,
          floor_id: issue.floorId as number | null,
        },
      }
      if (issue.floorId && request.attributes.referred_property) {
        request.attributes.referred_property.floor_id = issue.floorId
      }
    }
    const { data } = await this.issueApi.updateIssue(issueId, request)
    if (!data?.data || !data.ok) {
      throw new Error('API Error')
    }
    const updatedIssue = data.data
    const attachmentFiles = await this.attachmentEntityCreator(updatedIssue.attachment_files)
    return {
      ...toCamelCase(updatedIssue),
      floorId: updatedIssue.attributes.referred_property?.floor_id,
      floorName: updatedIssue.attributes.referred_property?.floor_name || '',
      propertyId: updatedIssue.attributes.referred_property.property_id,
      propertyName: updatedIssue.attributes.referred_property.property_name,
      attachmentFiles,
    }
  }

  attachmentEntityCreator(attachments: AttachmentFile[]): AttachmentFileEntity[] {
    return attachments.map((file: AttachmentFile): AttachmentFileEntity => {
      // todo: always use sasThumbnail regardless status, move check to component
      const sasThumbnail = file.status === FILE_STATUS.COMPLETED ? file.sas_thumbnail : file.sas_file
      const mimeType = file.mime_type || mime.lookup(file.name)

      return {
        id: file.id,
        name: file.name,
        mimeType: mimeType || '',
        sasFile: file.sas_file || '',
        sasThumbnail: sasThumbnail || '',
        isProtected: !!file.is_protected,
        filePermissions: file.file_permissions,
        status: file.status,
      }
    })
  }

  async getListIssueLink(params?: FilterIssueEntity, options: AxiosRequestConfig = {}): Promise<IssueEntities> {
    const { data } = await this.issueApi.listIssueLinks(
      params?.issueId as number,
      params?.linkType as string,
      params?.title,
      params?.page,
      params?.perPage,
      options
    )
    if (data?.data && data.ok) {
      const issuesResponse = {
        issues: (data.data as Issue[]).map((issue: Issue): IssueEntity => {
          return {
            ...toCamelCase(issue),
            floorId: issue.attributes.referred_property?.floor_id,
            floorName: issue.attributes.referred_property?.floor_name || '',
            propertyId: issue.attributes.referred_property.property_id,
            propertyName: issue.attributes.referred_property.property_name,
          }
        }),
        pagination: { ...toCamelCase(data.pagination) },
      }

      return issuesResponse
    } else {
      throw new Error('API Error')
    }
  }

  async getListMemoLink(params?: FilterIssueEntity, options: AxiosRequestConfig = {}): Promise<FacilityEntities> {
    const { data } = await this.issueApi.listIssueLinks(
      params?.issueId as number,
      params?.linkType as string,
      params?.title,
      params?.page,
      params?.perPage,
      options
    )
    if (data?.data && data.ok) {
      const facilitiesResponse = {
        facilities: (data.data as Facility[]).map((facility: Facility): FacilityEntity => {
          return {
            ...toCamelCase(facility),
            floorId: facility.attributes.referred_property?.floor_id,
            floorName: facility.attributes.referred_property?.floor_name || '',
            propertyId: facility.attributes.referred_property.property_id,
            propertyName: facility.attributes.referred_property.property_name,
          }
        }),
        pagination: { ...toCamelCase(data.pagination) },
      }

      return facilitiesResponse
    } else {
      throw new Error('API Error')
    }
  }
}
