// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Plugin } from '@nuxt/types'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { Auth0Scheme } from '@nuxtjs/auth-next'
import { initializeAxios } from '@/plugins/repository'
import { EMPTY_ERROR } from '@/constants/error'

const axiosInterceptor: Plugin = ({ $axios, app, $config }) => {
  const clientIdPostfix = $config.ENV !== 'production' ? `.${$config.ENV}` : ''
  const internalAxios = $axios.create({
    headers: {
      common: {
        'Content-Type': 'application/json',
        'x-client-id': `net.sensyn.property-hub${clientIdPostfix}`,
      },
    },
    timeout: 60000,
  })

  internalAxios.onRequest(async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const auth0Strategy = app.$auth.strategy as Auth0Scheme

    if (process.client && !auth0Strategy.token.status().valid()) {
      if (auth0Strategy.refreshToken.status().valid()) {
        try {
          await app.$auth.refreshTokens()
        } catch (e: any) {
          if (e?.response?.status === 403) {
            // refresh token invalidated
            await app.$auth.logout()
          } else {
            throw e
          }
        }
      } else {
        await app.$auth.logout()
      }
    }

    const token = auth0Strategy.token.get()
    if (token && typeof token === 'string') {
      config.headers.authorization = decodeURI(token)
    }

    return config
  })

  internalAxios.onError((error: AxiosError) => {
    console.error(error)

    if (internalAxios.isCancel(error) || error.config.hideSnackbar) {
      // 通常、スローされたエラーは最終的にnuxtのハンドリングに渡り、エラー画面が表示される
      // それを避けるため、componentでcatchして握りつぶすか、undefinedでrejectする必要がある
      // undefinedでrejectすることで、componentの処理は中断され、nuxtのエラー画面も表示されない、といった動作になる
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(undefined)
    }

    const e = { ...EMPTY_ERROR }

    // network error
    if (!error.response) {
      e.showSnackbar = true
      e.errorCode = 90100
      app.$accessor.error.setError(e)

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(undefined)
    }

    // client timeout
    if (error.code === 'ECONNABORTED') {
      e.showSnackbar = true
      e.errorCode = 90101
      app.$accessor.error.setError(e)

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(undefined)
    }

    e.statusCode = error.response.status
    e.errorCode = error.response.data?.error?.code
    e.message = error.response.data?.error?.message
    switch (e.statusCode) {
      case 400:
        switch (e.errorCode) {
          case 40000:
          case 40002:
            e.showSnackbar = true
            break
          case 40003:
          case 40004:
            return Promise.reject(error.response)
          case 40005:
            e.showSnackbar = true
            e.message = app.i18n.t('error.40005.text2').toString()
            break
          case 40006:
          case 40007:
          case 40008:
          case 40009:
            e.showSnackbar = false
            break
        }
        break
      case 401:
        // unauthorized
        app.$auth.logout()
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(undefined)
      case 403:
        switch (e.errorCode) {
          case 40300:
            // アクセス権のない施設・課題詳細ページを開いた時、404画面へ遷移
            e.statusCode = 404
            break
          case 40304:
            e.message = app.i18n.t('error.40304.text2').toString()
            e.showDialog = true
            break
          case 40301:
          case 40305:
            e.showDialog = true
            break
        }
        break
      case 404: {
        // 削除済みの施設ファイルを削除しようとした場合
        if (error?.response.config?.url?.includes('/directories/')) {
          return Promise.reject(error.response)
        }
        // 課題に紐づいた位置情報が既に削除されていたとき
        const isIssueRequest = error?.response.config?.url?.includes('/issues/')
        const isPutRequest = error?.response.config?.method === 'put'
        const isLinkedPanoramaRequest = error?.response.config?.data?.includes('linked_panorama_operation')
        if (isIssueRequest && isPutRequest && isLinkedPanoramaRequest) {
          return Promise.reject(error.response)
        }
        // 上記以外の場合はlayouts/default.vueで404表示にする
        break
      }
      default:
        e.showSnackbar = true
        break
    }

    if (e.errorCode === 40303) {
      e.showDialog = true
      e.showSnackbar = false
    }

    app.$accessor.error.setError(e)

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(undefined)
  })

  initializeAxios(internalAxios)
}

export default axiosInterceptor
