import { User, UserApi, UserDivision } from '@/api/api'
import { USER_ROLES } from '@/constants/index'

export interface RoleEntity {
  id: number
  name: string
}

export interface DivisionEntity {
  id: number
  name: string
}

export interface OrganizationEntity {
  id: number
  name: string
}

export interface UserEntity {
  id: number
  name: string
  nameKana: string
  iconUrl: string
  division: DivisionEntity | null
  role: RoleEntity | null
  organization: OrganizationEntity | null
  isDeleted: boolean
  isSuperUser: boolean
}

export interface LoginUserEntity extends UserEntity {
  isRestricted: boolean
  isAdmin: boolean
  isGeneral: boolean
  isShowingDirectory: boolean
  isShowingLinked: boolean
  isShowingFilterPIC: boolean
}

export class UserRepository {
  private readonly userApi: UserApi
  constructor(userApi: UserApi) {
    this.userApi = userApi
  }

  async getUsers(): Promise<UserEntity[]> {
    const { data } = await this.userApi.getUsers()
    if (data?.data && data.ok) {
      return data.data.map((v: User) => {
        return {
          id: v.id,
          name: v.name,
          nameKana: v.name_kana,
          iconUrl: v.icon_url,
          division: v.division ?? null, // divisionかnullが必ず帰ってくるので、undefinedの場合は無視して良い
          role: v.role ?? null, // 同上(Stoplightでrefとnullを共存させているため)
          organization: v.organization ?? null,
          isDeleted: v.is_deleted,
          isSuperUser: v.is_super_user,
        }
      })
    } else {
      return []
    }
  }

  async getUserMe(): Promise<LoginUserEntity | null> {
    const { data } = await this.userApi.getUserMe()
    if (data?.data && data.ok) {
      const user = data?.data
      return {
        id: user.id,
        name: user.name,
        nameKana: user.name_kana,
        iconUrl: user.icon_url,
        division: user.division ?? null, // divisionかnullが必ず帰ってくるので、undefinedの場合は無視して良い
        role: user.role ?? null, // 同上(Stoplightでrefとnullを共存させているため),
        organization: user.organization ?? null,
        isDeleted: user.is_deleted,
        isSuperUser: user.is_super_user,
        isShowingDirectory: user.is_showing_directory ?? false,
        isShowingLinked: user.is_showing_linked ?? false,
        isShowingFilterPIC: user.is_showing_filter_pic ?? false,
        isRestricted: user.role?.id === USER_ROLES.RESTRICTED,
        isAdmin: user.role?.id === USER_ROLES.ADMIN,
        isGeneral: user.role?.id === USER_ROLES.GENERAL,
      }
    } else {
      return null
    }
  }

  async getUserDivisions(): Promise<DivisionEntity[]> {
    const { data } = await this.userApi.getUserDivisions()
    if (data?.data && data.ok) {
      return data.data.map((v: UserDivision) => {
        return {
          id: v.id,
          name: v.name,
        }
      })
    } else {
      return []
    }
  }

  async getUserRoles(): Promise<RoleEntity[]> {
    const { data } = await this.userApi.getUserRoles()
    if (data?.data && data.ok) {
      return data.data.map((v: RoleEntity) => {
        return {
          id: v.id,
          name: v.name,
        }
      })
    } else {
      return []
    }
  }
}
